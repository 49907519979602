$app-width : rem-calc(1240); // width + scrollbar width
$app-after-before-width: calc((100vw - #{$app-width}) / 2) ;
$app-after-before-position: calc((-100vw + #{$app-width}) / 2) ;

.clickable {
	width: 100%;
}

/* @group V2 NEW COLORS */
	
	$v2-text-color-dark: #006667;
	$v2-text-color: #0F8D96;

/* @group PAGE TITLE */

	$page-title-color: #CCEBF2;

	.page-title-default {
		
		.columns {
			max-width: $app-width;
			margin: auto;
		}
		h2{
			text-align: left;
		}

		// Home



		// All pages except Home

			.Content:not(.home) & {
				.columns {
					padding-left: 0;
					padding-right: 0;
				}
			}
	}

/* @group FEATURES */

%features {
	border-top: 3px solid #d3edfd;
	position: relative;
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: -3px;
		width: 100%;
		height: calc(100% + 3px);
		border-top: 3px solid #d3edfd;
	}
	&:before {
		left: -100%;
	}
	&:after {
		right: -100%;
	}
	@include breakpoint(large) {
		&:before,
		&:after {
			width: $app-after-before-width;
		}
		&:before {
			left: $app-after-before-position;
		}
		&:after {
			right: $app-after-before-position;
		}
	}
}
.feat01 h3:before,
.feat01 h4:before {
	content: url("../skeleton/images/meet01.png");
}
.feat02 h3:before,
.feat02 h4:before {
	content: url("../skeleton/images/meet02.png");
}
.feat03 h3:before,
.feat03 h4:before {
	content: url("../skeleton/images/meet03.png");
}
.feat04 h3:before,
.feat04 h4:before {
	content: url("../skeleton/images/meet04.png");
}
.feat05 h3:before,
.feat05 h4:before {
	content: url("../skeleton/images/meet05.png");
}
.feat06 h3:before,
.feat06 h4:before {
	content: url("../skeleton/images/meet06.png");
}
[class*="feat"] {
	@include breakpoint(medium) {
		margin-bottom: 3rem;

	}
	h3, h4 {
		margin-bottom: $global-margin;
		// text-transform: uppercase;
		font-size: 1.5rem;
		&:before {
			display: block;
			margin-bottom: $global-margin;
		}
	}
	img {
		margin-bottom: $global-margin*1.5;
	}
}

/* @group PAGE SUBTITLE */
	
	.page-subtitle {
		font-family: "square-medium";
		color: $dark-gray;
		font-size: calc(#{rem-calc(16)} + 1vw);
		line-height: 1.2;
		margin-bottom: 2rem;
	}

/* @group CONTENT WRAPPER */

	.row.Content {
		max-width: rem-calc(1240) !important;
		margin: auto !important;
		padding-top: $global-margin * 3;
	}

	.Content p strong,
	.Content ul strong {
		font-weight: normal;
		font-family: 'square-bold';
	}

/* @group SECTIONS  */
	
	.Content [class*="section"] {
		padding: 5vh 0;
		position: relative;
	}

/* @group HOME PAGE */

	// @import "content-home";
	@import "content-home-v2-jul19";

/* @group ABOUT */

	.Content.about {
		// padding-top: $global-padding * 3;
		// text-align: center;
		h3,
		h4 {
			color: #3bb0c9;
		}
		[class*="section"] {
			&:nth-child(odd) {
				background-color: #e8f6fe;
				color: #4f4f4f;
				&:before,
				&:after {
					content: "";
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: #e8f6fe;
				}
				&:before {
					left: -100%;
				}
				&:after {
					right: -100%;
				}
			}
		}
		.section02 {
			color: darken($dark-gray, 15%);
			background-image: url("../skeleton/images/home-section01.png");
			background-repeat: no-repeat;
			background-position: right bottom;
			background-size: 39% auto;
			h4 {
				margin-bottom: $global-margin * 2;
				&:before {
					display: block;
					margin-bottom: $global-margin * 2;
				}
			}
			.columns {
				margin-top: $global-margin * 3;
				*:last-child {
					margin-bottom: 0;
				}
			}
			.columns:first-child h4:before {
				content: url("../skeleton/images/about01.png");
			}
			.columns:nth-child(2) h4:before {
				content: url("../skeleton/images/about02.png");
			}
			.columns:nth-child(3) h4:before {
				content: url("../skeleton/images/about03.png");
			}
		}
		.section03 {
			a.promo {
				display: inline-block;
				margin: 0 0 0 1rem;
			}
		}
	}

/* @group CONTACT US */
	
	.Content.contact-us {
		h3,
		h4 {
			color: #3bb0c9;
		}
		[class*="section"] {
			&:nth-child(odd) {
				background-color: #e8f6fe;
				color: #4f4f4f;
				&:before,
				&:after {
					content: "";
					position: absolute;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: #e8f6fe;
				}
				&:before {
					left: -100%;
				}
				&:after {
					right: -100%;
				}
			}
		}
	}

/* @group CITIES */

.Content.participating-cities {
	h3,
	h4 {
		color: #3bb0c9;
	}
	[class*=section] h3 {
		margin-bottom: 0;
	}
	[class*="section"] {
		&:nth-child(odd) {
			background-color: #e8f6fe;
			color: #4f4f4f;
			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #e8f6fe;
			}
			&:before {
				left: -100%;
			}
			&:after {
				right: -100%;
			}
		}
	}
}

/* @group ABOUT JRC*/

.Content.about-jrc {
	h3,
	h4 {
		color: #3bb0c9;
	}
	[class*="section"] {
		&:nth-child(odd) {
			background-color: #e8f6fe;
			color: #4f4f4f;
			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #e8f6fe;
			}
			&:before {
				left: -100%;
			}
			&:after {
				right: -100%;
			}
		}
	}
}

/* @group FEATURES */

.Content.features {
	.section01 {
		position: relative;
		.columns:nth-child(1){
			position: relative;	
			z-index: +1;
		}
		.columns:nth-child(2){
			height: 30vw;
			max-height: 300px;
			font-size: 0;	
			&:after {
				content: "";
				background-image: url("../skeleton/images/features-section01.png");
				background-repeat: no-repeat;
				background-position: center bottom;
				background-size: auto 100%;
				width: 100%;
				height: 100%;
				display: block;
				@include breakpoint(medium) {
					background-position: right bottom;
					height: 150%;
					position: absolute;	
					bottom: 0;
					right: -10%;	
				}
			}
		}
	}
	.section02 {
		@extend %features;
		padding: $global-padding * 5 0;
	}
}


/* @group APP FOOTER */

.App .Footer {
	// @extend %features;
	background: #0088ad;
	position: relative;
	padding: $global-padding * 3 0;
	border-color:#0088ad;
	padding-left: $global-margin*2.5;
	padding-right: $global-margin*2.5;
	// &:before,
	&:after {
		background: #0088ad;
		content: "";
		position: absolute;
		left: -25%;
		top: 0;
		width: 150%;
		height: 100%;
		z-index: -1;
		// border-color:#0088ad;
	}
	h3 {
		color: #fff;
		font-size: 1.2rem;
	}
	hr {
		border-bottom: 1px solid darken(#0088ad,7%);
	}
	ul {
		font-family: "square-regular";
		list-style: none;
		margin: 0 0 $global-margin 0;
	}
	a {
		color: #fff;
		text-decoration: none !important;
	}
}

/* @group APP FOOTER SMALL */

.App .FooterSmall {
	font-size: 0.8em;
	padding: $global-padding 0;
	p {
		margin: 0;
	}
	ul {
		margin: 0;
		list-style: none;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.LocaleSwitcher {
		padding-right: $global-padding * 2;
	}
	select {
		font-family: "square-regular";
		font-size: 1em;
		margin: 0;
		border: 0;
	}
	a {
		font-family: "square-regular";
		color: $dark-gray;
		text-decoration: none !important;
	}
	.dynamic p {
		padding-left: $global-padding * 3;
		background: url("../skeleton/images/flag-eu.png") no-repeat left center;
		background-size: auto 100%;
	}
	[class*="icon-"] {
		font-size: 0;
		display: block;
		margin: 0 5px;
		&:before {
			vertical-align: middle;
			font-size: rem-calc(24);
		}
	}
}

/* @group ERROR 404 */
.Content.error404 {
	height: 100%;
	p:before {
		display: block;
		content: url("../skeleton/images/error404.png");
	}
}
