// FOR PROPER DEVELOPMENT
@import "~foundation-sites/scss/foundation.scss";

// JUST FOR STATIC SASS BUILD (Build System-> SASS)
// @import "../../node_modules/foundation-sites/scss/foundation.scss";

/* @group APP VARIABLES */
$header-height-mobile: rem-calc(50);
$header-height: rem-calc(70);
$app-shadow: 0px 0px 4px rgba(#000, 0.3);
$app-shadow-large: 0px 0px 10px rgba(#000, 0.3);
$app-shadow-large-light: 0px 0px 14px rgba(#000, 0.1);
$app-map-controls-size: rem-calc(50);

$app-color-gold: #e8b837;
$app-color-gold-dark: #56420B;
$app-color-red: #ff5252;
$app-color-green: #32CC78;
$app-color-poi: #0088ad;
$app-color-puzzle: #cc33bf;
$app-color-duk: #54b7d2;
$app-color-add-poi: #27a9a9;
$app-color-cityStory: #7b1d96;

$app-color-museums-galleries: #b72;
$app-cultural-heritage-sites: #f60;
$app-tourist-attractions: #27a9a9;
$app-city-amenities: #a3c;
$app-cinemas-theatres: #538a41;
$app-city-stories: #d826b4;

$app-color-favorites: #32CC78;
$app-color-contributions: #27a9a9;
$app-color-recent: #a3c;

$app-color-vibrancy: #ecb842;
$app-color-economy: #3bb0c9;
$app-color-environment: #005640;

$h4-margin-bottom: $paragraph-margin-bottom;

/* @group SCROLL BAR */
	
	%scrollbar {
		&::-webkit-scrollbar {
		    -webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
		    width: 10px;
		}

		&::-webkit-scrollbar:horizontal {
		    height: 3px;
		}

		&::-webkit-scrollbar-thumb {
		    background-color: rgba(0, 0, 0, .5);
		    border-radius: 6px;
		    border: 3px solid #ffffff;
		}

		&::-webkit-scrollbar-track {
		    border-radius: 6px;  
		}
	}

/* @group APP FONTS */
@import "fonts";

/* @group FOUNDATION */
@import "_settings.scss";
// @include foundation-everything;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-global-styles;
@include foundation-visibility-classes;
@include foundation-forms;
@include foundation-typography;
@include foundation-button;
@include foundation-button-group;
@include foundation-callout;
@include foundation-responsive-embed;
@include foundation-card;
@include foundation-progress-bar;
@include foundation-reveal;
// @include foundation-menu;

/* @group APP SPECIFICS */
@import "app";
@import "content";
@import "loader";
@import "header";
@import "cityselector";
@import "tags";
@import "sidebar";
@import "main-content";
@import "map";
@import "slidingPanel";
// @import 'poiPanel';
@import "poiItemPanel";
@import "favorite";
@import "content-rating";
@import "profile";
@import "messages";
@import "cards";
@import "react-autocomplete";
@import "modal";