.Profile {
	// @extend .row.Content;
	.row:not(.expanded) &.row {
		max-width: 95%;
		margin-left: auto;
		margin-right: auto;
		> .columns {
			position: relative;
			padding-top: $global-padding*3;
		}
	}

	/* @group USER */

		.user {
			z-index: +1;
			> .callout {
				// box-shadow: $app-shadow;
				box-shadow: $app-shadow-large-light;
			}
			.nickname {
				margin-bottom: $global-margin;
				h3 {
					margin-bottom: 0;
					color: $black;
				}
			}
			.euLogin {
				font-size: 0.8em;
			}
			.actions {
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				.button {
					margin-top: $global-margin;
				}
			}
			// p {
			// 	font-size: 0.9rem;
			// 	&:last-of-type {
			// 		margin-bottom: $global-margin*2;
			// 	}
			// }
			// strong {
			// 	font-size: 1.3em;
			// 	font-family: 'square-bold';
			// }
			// form {
			// 	.button {
			// 		margin-bottom: 0;
			// 	}
			// }
			// .edit {
			// 	font-style: italic;
			// 	font-size: 0.8em;
			// 	margin-top: 1em;
			// }
			// .actions .NotificationsPanel,
			// .actions button {
			// 	display: inline;
			// }
		}
		.UserAvatarUploader {
			margin-bottom: $global-margin;
		}

	/* @group INFO */
		
		.info {
			h2 {
				margin-bottom: $global-margin*2;
				&:before {
					background: $app-color-gold;
				}
			}
			h2, h3 {
				&:before {
					color: #fff;
					border-radius: 50%;
					font-size: 0.6em;
					padding: 0.5em;
					margin-right: 1em;
					width: rem-calc(32);
					height: rem-calc(32);
				}
			}
		}

		header {
			position: relative;
			padding: 0 0 $global-margin*3 0;
			margin: 0 0 $global-margin*3 0;
			> * {
				z-index: 1;
				position: relative;
			}
			&:before {
				content: "";
				display: block;
				width: 100vw;
				height: calc(100% + 3rem);
				position: absolute;
				left: -6vw;
				top: -3rem;
				z-index: 0;
				background: $light-gray;
				@include breakpoint(medium) {
					left: -44vw;
					top: -3rem;
				}
			}
		}

		.quicklinks {
			.button {
				display: inline-block;
				margin-top: $global-margin;
				margin-right: $global-margin;
				margin-bottom: 0;
				&:last-child {
				}
			}
		}
		
		.card {
			animation: mymove 0.3s;
			margin-right: $card-margin-bottom*0.5;
			margin-left: $card-margin-bottom*0.5;
			@include breakpoint(xlarge) {
				display: inline-block;
				vertical-align: top;
				width: calc(50% - #{$card-margin-bottom});
			}
		}
		@keyframes mymove {
		  0% {opacity: 0;}
		  100% {opacity: 1;}
		}

		.contributions,
		.favourites,
		.recent {
			margin-bottom: $global-margin*3;
			h3 {
				margin-bottom: $global-margin*2;
			}
			.button {
				display: block;
				margin-top: $global-margin*2;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.contributions {
			h3[class*="icon-"]:before,
			div[class*="icon-"]:before {
				background: $app-color-contributions;
				color: $white;
			}
		}

		.favourites{
			h3[class*="icon-"]:before,
			.card-section[class*="icon-"]:before {
				background: $app-color-favorites;
				color: $white;
			}
		}
		
		.recent {
			h3[class*="icon-"]:before,
			.card-section[class*="icon-"]:before {
				background: $app-color-recent;
				color: $white;
			}
		}

}

/* @group AVATAR */
.UserAvatarUploader {
	.UserAvatar {
		img {
			width: rem-calc(96);
			height: rem-calc(96);
			
		}
		margin-bottom: $global-margin*2;
	}
	.button {
		// display: block;
			margin-bottom: 0;
		&:last-child {
		}
	}
	input.upload {
		width: 0em;
		height: 0em;
		margin: 0;
		position: absolute;
		left: -990px;
	}
	button.remove {
		span {
			display: none;
		}
	}
	
}