$loader-size: 56px;

.lds-ripple {
	display: inline-block;
	width: $loader-size;
	height: $loader-size;
	position: absolute;
	z-index: +1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	// bourboulithra:
	& > span {
		position: absolute;
		border: 2px solid #0088ad;
		opacity: 1;
		border-radius: 50%;
		animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
		&:nth-child(2) {
			animation-delay: -0.5s;
		}
	}
	// message:
	& > div {
		position: absolute;
		text-transform: uppercase;
		bottom: -40px;
		left: -999px;
		right: -999px;
		margin: auto;
		text-align: center;

		font-size: 0.6em;
		font-family: Arial, sans-serif;
		display: block;
		color: #666;
		.button {
			display: inline-block;
			vertical-align: inherit;
			text-transform: uppercase;
		}
	}
	.message & {
		// display: flex;
		position: relative;
		width: auto;
		padding: 0.5rem 0 0.5rem rem-calc(50);
		height: auto;
		// min-height: $loader-size;
		& > span {
			display: inline-block;
			transform: scale(0.6, 0.6);
		}
		& > div {
			position: static;
			display: inline-block;
			width: 100% !important;
			text-align: left;
			font-size: 0.7em !important;
			line-height: 1.3;
			padding: rem-calc(12) 0 0 0;
		}
		button {
			margin: 0.5rem 0;
			display: block;
		}
	}
	#root & > div,
	.row.App & > div {
		width: 300%;
	}
}

@keyframes lds-ripple {
	0% {
		top: $loader-size/2;
		left: $loader-size/2;
		width: 0;
		height: 0;
		opacity: 0;
	}
	5% {
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	70% {
		opacity: 0.5;
	}
	100% {
		top: 0px;
		left: 0px;
		width: $loader-size;
		height: $loader-size;
		opacity: 0;
	}
}
