.SlidingPanel {
	position: fixed;
	right: 0;
	top: 0;
	width: 100vw;
	max-width: 800px !important;
	background: #fff;
	transform: translate3d(100%, 0, 0);
	opacity: 0.3;
	z-index: 9999999;
	transition: transform 200ms, opacity 200ms; /* fast close */
	height: 100%;
	overflow: hidden;

	@include breakpoint(medium) {
		top: auto;
		height: calc(100% - #{$header-height});
		width: 80vw;
	}
	& > .inner {
		margin: 0 !important;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		text-align: center;
		& > .content {
			padding-bottom: $global-padding * 6;
		}
	}
	h2 {
		margin-top: $global-padding * 2;
		@include breakpoint(small only) {
			margin-bottom: 1.5rem;
		}
		& + .button-group {
			margin-top: -$global-margin;
			// margin-bottom: $global-margin*2;
		}
	}
	header {
		margin-top: $global-padding * 2;
		h2 {
			margin-top: 0;
		}
	}

	&.visible {
		opacity: 1;
		transform: translate3d(0%, 0, 0);
		transition: transform 500ms, opacity 500ms; /* slow open */
		box-shadow: $app-shadow;
	}

	/* @group POSTER */

		.poster {
			display: block;
			width: 100%;
			max-width: none;
		}

		.columns.content .poster {
			margin-left: -#{$global-padding*3};
			margin-right: -#{$global-padding*3};
			width: calc(100% + #{$global-padding*6})
		}

	/* @group CLOSE + BACK BUTTON */

		.close, .back {
			display: block;
			padding: rem-calc(12.5) rem-calc(18);
			position: absolute;
			z-index: +1;
			top: $global-padding*0;
			right: $global-padding*0;
			font-size: rem-calc(21);
			line-height: 1;
			cursor: pointer;
			text-shadow: 0 0 8px #000;
			color: $anchor-color;
			&.white {
				color: #fff;
			}
			@include breakpoint(medium) {
				top: $global-padding;
				right: $global-padding;
			}
		}
		.close {
			@extend .close.white;
		}

	/* @group CALLOUT */

		.callout {
			background: rgba(#fff, 0.2);
			color: #fff;
		}

	/* @group CONTENT OWNERSHIP CHECKBOX */

		.ContentOwnershipConfirmationField {
			
			// since the validation tooltip is linked with the input element, we placed the input behind the styled checkbox in order for the validation tooltip to appear in the correct position
			input {
				position: absolute;
				top: 28px;
				left: 28px;
				z-index: -2;
			}

			label {
				text-align: left;
				opacity: 1;

				// Better checkbox positioning
				&:before {
					top: 4px;
				}

				&:after {
					top: 2px;
				}
			}
		}


	/* @group CATEGORIES */

		ul.tags {
			margin: (-$global-margin * 1.5) 0 $global-margin * 2.5 0;
			list-style: none;
			li {
				display: inline-block;
				margin: 0 rem-calc(5) rem-calc(5) 0;
				line-height: 1;
				font-family: "square-regular", sans-serif;
				border-radius: 10rem;
				border: 1px solid rgba(0, 0, 0, 0.2);
				font-size: 0.875em;
				padding: $global-padding * 0.7 $global-padding;
			}
		}

	/* @group BODY */

		.body {
			margin: 0 0 $global-margin * 2 0;
		}

	/* @group LOADER */

		.lds-ripple {
			&:before {
				display: block;
				content: "";
				width: 100vw;
				height: 100vh;
				background: rgba(#000,0.6);
				position: absolute;
				left: -9999px;
				right: -9999px;
				bottom: -9999px;
				top: -9999px;
				margin: auto;
			}
		}

	/* @group POI ITEMS LIST */

		.poiItemsList {
			.button {
				background: $app-color-puzzle;
				margin-bottom: $global-margin * 0.3 !important;
				text-decoration: none;
				&:before {
					font-size: 1.2em;
					display: inline-block;
					vertical-align: text-bottom;
				}
			}
		}

		.button.solved,
		.button.visited {
			opacity: 0.7;
			em {
				display: block;
				margin-top: 2px;
				opacity: 0.7;
			}
		}

	/* @group POI PANELS */

		&.PoiPanel,
		&.PoiItemPanel,
		&.ContentReviewPanel,
		&.CityPanel,
		&.PoiSubmissionPanel,
		&.CityStorySubmissionPanel,
		&.PoiEditPanel {
			> * {
				color: #fff;
			}
			a:not(.button) {
				color: #fff;
				text-decoration: underline;
			}
			.lds-ripple {
				div {
					color: #fff;
				}
				span {
					border-color: white;
				}
			}
		}

	/* @group POI  */

		&.PoiPanel {
			> .inner {
				text-align: left;
			}
			background: $app-color-poi;
			.lds-ripple {
				&:before {
					background: rgba($app-color-poi,0.6);
				}
			}
			.PanelFooter {
				background: rgba(darken($app-color-poi, 3%), 0.8);
			}
		}

	/* @group POI Item (puzzle) */

		&.PoiItemPanel {
			background: $app-color-puzzle;
			.lds-ripple {
				&:before {
					background: rgba($app-color-puzzle,0.6);
				}
			}
			.PanelFooter {
				background: rgba(darken($app-color-puzzle, 5%), 0.9);
			}
		}

	/* @group CITY STORY SUBMISSION PANEL */

		&.CityStorySubmissionPanel {
			fieldset {
				// border: 3px solid pink;
				text-align: left;
				overflow: hidden;
				background: darken(#3f528c,5%);
				border-radius: $callout-radius;
				padding: $callout-padding*1.5;
				margin-bottom: $global-margin;
				transition: background 0.2s, max-height 0.2s;
				label,
				.react-autocomplete {
					transform: translateX(-50px);
					opacity: 0;
					transition: transform 0.3s, opacity 0.3s;
					&:last-of-type input {
						margin-bottom: 0;
					}
				}
				.button-group {
					margin-bottom: 0;
					.button {
						padding-left: 0.5rem;
						padding-right: 0.5rem;
						margin-bottom: 0;
						background: none;
					}
				}
				.content {
					max-height: 0;
					transition: max-height 0.4s;
					overflow: hidden;
				}
				&.expanded {
					background: rgba($callout-background,0.1);
					label,
					.react-autocomplete {
						transform: translateX(0);
						opacity: 1;
					}
					.content {
						max-height: 200vh;
					}
				}
				&.collapsed {
					.icon-hide:before {
						content: $icon-show;
					}
					.content {
						max-height: 0;
					}
				}
				&:last-of-type {
					margin-bottom: $global-margin*2;
				}
			}
			legend {
				cursor: pointer;
				font-family: 'square-regular', sans-serif;
				font-size: 1.3em;
				margin-bottom: 0;
			}
		}

	/* @group DID YOU KNOW PANEL */

		&.CityPanel {
			background: $app-color-duk;
			> .inner {
				text-align: left;
			}
			.lds-ripple {
				&:before {
					background: rgba($app-color-duk,0.6);
				}
			}
			.PanelFooter {
				background: rgba(darken($app-color-duk, 5%), 0.9);
			}
		}

	/* @group CITY STORY */

		&.c3m_city_story {
			background: $app-color-cityStory;
			.PanelFooter {
				background: rgba(darken($app-color-cityStory, 5%), 0.9);
			}
		}

	/* @group REVIEW PANEL */

		&.ContentReviewPanel {
			background: $app-color-poi;
			.lds-ripple {
				&:before {
					background: rgba($app-color-poi,0.6);
				}
			}
			.content {
				text-align: center;
			}
			.ratings {
				list-style-type: none;
				text-align: left;
				margin: 0;
				font-size: 0.8rem;
				.Stars:after {
					display: inline-block;
					margin-left: rem-calc(4);
				}
				li {
					padding: $global-padding $global-padding;
					margin: $global-padding*0.5 0;
					border-radius: $global-radius*2;
					clear: both;
					min-height: 2em;
					background: rgba(0,0,0,0.1);
					.name {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 90%;
					}
					/* a user which has been deleted: rare but possible */
					.name.deleted {
						text-decoration: line-through;
					}
					.edited {
						opacity: 0.7;
						font-size: 0.7em;
						line-height: 1;
						p {
							margin: 0;
						}
					}
					
					//&.self {
						// background: red;
					//}

				}
			}
			
		}

	/* @group WALL OF FAME PANEL */

		&.WallOfFamePanel {
			background: $app-color-gold;
			> * {
				color: $app-color-gold-dark;
			}
			a {
				color: $app-color-gold-dark;
				text-decoration: underline;
			}
			.close {
				color: #fff;
			}
			.lds-ripple {
				div {
					color: #fff;
				}
				span {
					border-color: #fff;
				}
			}
			.usersList {
				margin: 0;
				counter-reset: users;
				text-align: left;
				.UserAvatar {
					min-width: rem-calc(60);
					padding-right: 1rem;
					img {
						width: rem-calc(50);
						min-width: rem-calc(50);
						height: auto;
					}
				}
				li {
					position: relative;
					display: flex;
					border-radius: 3rem;
					align-items: center;
					margin: 0 0 0.5rem 1.5rem;
					line-height: 1.2;
					padding: $global-padding*0.3 $global-padding $global-padding*0.3 $global-padding*0.3;
					&:nth-child(1) {
						font-size: 1.4em;
					}
					&:nth-child(odd) {
						background: rgba(#fff, 0.1);
					}
					&.current {
						background: rgba(#fff, 0.3);
					}
					&:hover {
						background: rgba(#fff, 0.6);
					}
					&:before {
						counter-increment: users;
	    				content: counter(users);
	    				position: absolute;
	    				top: 0;
	    				bottom: 0;
	    				height: 0;
	    				line-height: 0;
	    				left: -1.5rem;
	    				margin: auto;
	    				font-size: 0.7rem;
	    				font-family: 'square-medium';
					}
					
				}
				.name {
					flex-grow:1;
					font-weight: bold;
				}
				.score {
					flex-shrink:1;
					text-align: right;
					position: relative;
					padding-right: 1.5rem;
					font-family: 'square-regular';
					&:before {
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						line-height: 0;
						height: rem-calc(2);
						font-size: 1rem;
						margin: auto;
						opacity: 0.5
					}
				}
				@include breakpoint(small only) {
					font-size: 0.8rem;
				}
			}
		}
	
	/* @group SUBMISSONS PANEL */

		&.PoiSubmissionPanel,
		&.CityStorySubmissionPanel,
		&.PoiEditPanel {
			// background: $app-color-add-poi;
			background: #3f528c;
			label {
				font-size: 1.125rem;
				> *:last-child,
				+ div .react-autocomplete {
					margin-top: 1rem;
					margin-bottom: 2rem;
					+ div {
						margin-bottom: 2rem;
					}
				}
				+ div {
					display: block !important;
				}
			}
			span.required {
				color: rgba(255, 255, 255, 0.7);
			}
			.button.upload {
				background: $input-background;
				color: $input-color;
			}
			textarea {
				resize: vertical;
			}
			.icon-create-poi {
				&:before {
					display: block;
					font-size: 3rem;
					margin-bottom: $global-margin;
				}
			}
			@include breakpoint(small only) {
				.icon-create-poi {
					font-size: 0.8rem;
					margin: -$callout-padding*1.5;
					&:before {
						display: none;
					}
				}
			}
			.MapLocationPreview {
				margin-bottom: $global-margin;
				border-radius: $input-radius;
			}
		}
	
	/* @group PLACES */

		&.PlacesPanel {
			position: absolute;
			left: 0;
			top: $header-height-mobile;
			height: calc(100% - #{$header-height-mobile});
			z-index: 1050; /* leaflet map is 400, its default controls (top, bottom) are 1000 but we've changed the top controls to 1100*/
			width: 100% !important;
			max-width: 100% !important;
			transition: transform 200ms; /* fast close */
			background: rgba(#fff, 0.8);
			.content {
				padding-top: $global-margin*9;
				display: flex;
				flex-wrap: wrap;
				width: 100vw;
			}
			.close {
				color: $anchor-color;
				text-shadow: none;
			}
			@include breakpoint(medium) {
				top: auto;
				height: calc(100% - #{$header-height});
				.content {
					padding-top: $global-margin*8;
				}
			}
			.card {
				margin: 0 4% 4% 0;
				flex-basis: 100%;
				flex-grow: 1;
				@include breakpoint(medium) {
					flex-grow: 0;
					margin: 0 2% 4% 2%;
					flex-basis: 46%;
				}
				@include breakpoint(large) {
					flex-basis: 29%;
				}
				@include breakpoint(xlarge) {
					flex-basis: 20%;
				}
			}
			// more button
			.more {
				margin-left: auto;
				margin-right: auto;
			}
		}

	/* @group ONLINE DISCUSSIONS */

		&.PoiItemPanel.c3m_online_discussion {
			.comment {
				background: rgba(0,0,0,0.1);
				padding: $global-padding $global-padding;
				margin: $global-padding*0.5 0;
				border-radius: $global-radius*2;
				font-size: 0.8rem;
				/* comment by admin needs to stand out */
				&.admin {
					background: rgba(255, 255, 0, 0.3);
				}
			}
			footer.submit {
				background: rgba($app-color-puzzle, 0.9);
			}
			.dates {
				font-size: 0.9rem;
				padding: 0;
				margin-bottom: 2rem;
				margin-top: 1rem;
				strong {
					display: block;
				}
				.columns {
					margin-top: $global-margin;
					margin-bottom: $global-margin;
				}
			}
		}
		
		/* @group COMMON CLASSES */

			.Ago {
				font-size: 0.7rem;
			}

			.name {
				display: block;
				padding-left: 0.5rem;
				font-family: 'square-medium';
				font-size: 1.2em;
			}
			/* a user which has been deleted: rare but possible */
			.name.deleted {
				text-decoration: line-through;
			}


			.Comment {
				padding: 0 0 0 $global-padding*2;
				margin-left: $global-margin*1.3;
				margin-top: $global-margin*0.5;
				border-left: 1px solid rgba(#fff,0.5);
				color: rgba(#fff,0.85);
			}

			footer.submit {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				margin: 0;
				padding: 0.7rem 1rem;
				align-items: center;
				text-align: left;
				font-size: 0.8rem;

				@include breakpoint(medium) {
					_:-ms-input-placeholder, :root & {
						left: auto;
						bottom: #{$header-height};
						margin-left: -3rem;
					}
				}
				.row {
					align-items : center;
					margin-left: 0;
					margin-right: 0;
					justify-content: center;
					> * {
						margin-right: $global-margin*0.3;
						margin-left: $global-margin*0.3;
						@include breakpoint(medium) {
							margin-right: $global-margin*1;
							margin-left: $global-margin*1;
						}
					}
				}

				background: rgba($app-color-poi, 0.9);

				.UserAvatar {
					img {
						zoom: 0.4;
					}
				}
				.button, textarea {
					margin-bottom: 0;
				}
				p.pending,
				p.serverError {
					margin-bottom: $global-margin;
					text-align: left;
					&:last-child {
						margin-bottom: 0;
					}
					&:before {
						display: inline-block;
						vertical-align: middle;
						margin-right: $global-margin;
						margin-bottom: 0;
						text-align: center;
						font-size: 	0.7rem;
						width: $global-margin*1.7;
						height: $global-margin*1.7;
						line-height: 0.9em;
					}
				}
			}
}

/* @group PANEL FOOTER */

	.PanelFooter {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		margin: 0;
		padding: 0.7rem 1rem;
		align-items: center;
		text-align: left;
		font-size: 0.8rem;
		@include breakpoint(medium) {
			_:-ms-input-placeholder, :root & {
				left: auto;
				bottom: #{$header-height};
				margin-left: -3rem;
			}
		}
		.row {
			align-items : center;
			margin-left: 0;
			margin-right: 0;
			justify-content: center;
			> * {
				margin-right: $global-margin*0.3;
				margin-left: $global-margin*0.3;
				@include breakpoint(medium) {
					margin-right: $global-margin*1;
					margin-left: $global-margin*1;
				}
			}
		}

		.icon-distance:before {
			display: none;
			@include breakpoint(medium) {
				display: inline-block;
			}
		}

		.rateMe {
			padding: 0.2rem 0.2rem;
			margin-bottom: 0;
			text-decoration: none;
			background: rgba(#fff,0.1);
			&:hover {
				background: rgba(#000,0.1);
			}
			&:before {
				display: inline-block;
				color: $app-color-gold;
			}
			.ContentRating {
				display: inline-block;
			}
		}
	}


/* @group CITY DATA */
$color-population: blue;
$color-gdp: green;
$color-employment: deeppink;

.CityData {
	background: $app-color-duk;
	> .inner {
		text-align: left;
	}
	//h3 {
		// margin-top: $global-margin*3;
		// margin-bottom: $global-margin*2;
	//}

	.indicator-title {
		font-size: 1rem;
		display: block;
		color: $black;
		margin: $global-margin $global-margin*2 $global-margin $global-margin*2;

	}

	.average {
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		width: 14px;
		max-width: 14px;
		height: 14px;
		font-size: 0px;
		text-align: center;
		transition: max-width 0.3s;
		border-radius: 4px;
		margin-left: -7px;
		white-space: nowrap;
		overflow: hidden;
		&:before {
			content: '';
			border: 1px solid #fff;
			display: block;
			width: 10px;
			height: 10px;
		    position: absolute;
		    left: -9999px;
		    right: -9999px;
		    top: -9999px;
		    bottom: -9999px;
		    margin: auto;
		}
		&.population {
			top: -7px;
			&:before {
				background: $black;
			}
		}
		&.gdp {
			top: calc(50% - 7px);
			&:before {
				background: $black;
				border-radius: 50%;
			}
		}
		&.employment {
			top: calc(100% - 7px);
			&:before {
				background: $black;
				transform: rotate(45deg);
			}
		}
	}
	.progress {
		position: relative;
		border-radius: $global-radius;
		margin: 0 $global-margin*2 $global-margin*2 $global-margin*5;
		.average {
			cursor: pointer;
			&:hover {
				font-size: 13px;
				width: auto;
				max-width: 130px;
				height: auto;
				border: 0;
				z-index: +1;
				cursor: pointer;
				background: $black;
				padding-left: 6px;
				padding-right: 6px;
				&:after {
					content: ": " attr(data-title);
				}
				&:before {
					display: none;
				}
			}
		}
	}

	.progress-meter {
		background: rgba(#fff, 0.5);
		border-radius: $global-radius;
	}

	.progress-meter-text{
		font-size: 0.9rem;
		left: -$global-margin*3;
		transform: translate(0, -50%);
		border-radius: $global-radius;
	}

	.legend {
		font-size: 0.7em;
		.average {
			position: relative;
			margin-right: 0.5rem;
			top: -2px;
		}
		li {
			display: inline-block;
			margin-left: .5rem;
			margin-right: 1rem;
			color: $black;
		}
	}
	.legend ~ p {
		font-size: 0.7em;
	}

	section {
		margin-bottom: 1rem;
		padding: 0;
		.button:after {
			content: "#{$icon-arrow-down}";
			position: absolute;
			right: 2rem;
			font-size: 0.9em;
			color: $app-color-poi;
		}
		&.expanded .button:after {
			content: "#{$icon-arrow-up}";
		}
		&.c3Index {
			.progress-meter {
				background: rgba(#fff, 1);
			}
			.indicator-title {
				font-size: 1.5em;
				margin-top: 0;
			}
		}
		&.vibrancy {
			background: $app-color-vibrancy;
			.button,
			.progress-meter-text,
			.indicator-title {
				color: $app-color-gold-dark
			}
			.data {
				.progress {
					background: rgba($app-color-vibrancy,0.2);
				}
				.progress-meter-text {
					color: darken($app-color-vibrancy, 25%);
				}
				.progress-meter {
					background: $app-color-vibrancy;
				}
			}
		}
		&.economy {
			background: $app-color-economy;
			.data {
				.progress {
					background: rgba($app-color-economy,0.2);
				}
				.progress-meter-text {
					color: darken($app-color-economy, 10%);
				}
				.progress-meter{
					background: $app-color-economy;
				}
			}
		}
		&.environment {
			background: $app-color-environment;
			.data {
				.progress {
					background: rgba($app-color-environment,0.2);
				}
				.progress-meter-text {
					color: lighten($app-color-environment, 10%);
				}
				.progress-meter {
					background: $app-color-environment;
				}
			}
		}

		
		h3 {
			margin-top: 0;
			margin-bottom: 1rem;
		}
		.button {
			text-align: left;
			margin-bottom: 0;
			padding-left: $global-padding*2;
			background: transparent;
			@include breakpoint(small only) {
				font-size: 1.2rem;
			}
			&:after {
				font-family: "#{$icomoon-font-family}";
				color: #fff;
			}
			&:focus {
				outline: 0;
			}
			&:before{
				font-size: 1em;
				vertical-align: middle;
			}
		}

		// .progress-meter-text {
		// }


		&.expanded {
			.data {
				max-height: 600px;
				transition-delay:0s;
				overflow: visible;
				.progress-meter {
					transform: scale(1,1);
					transition-delay:0.2s;
				}
				.progress-meter-text{
					// color: rgba($black,0.6);
					opacity:1;
					transition-delay:0.4s;
				}
				.average {
					display: block;
				} 
			}
		}
		
		.data {
			max-height: 0;
			overflow: hidden;
			transition: max-height;
			transition-duration:0.2s;
			transition-delay:0.4s;
			background: #fff;
			border-radius: 0 0 $callout-radius $callout-radius;
			.row:last-child {
				padding-bottom: $global-margin*2;
			}
			.progress {
				margin-bottom: 0;
			}
			.progress-meter {
				transform: scale(0,1);
				transform-origin: left;
				transition: transform;
				transition-duration:0.2s;
				transition-delay:0.2s;
			}
			.progress-meter-text{
				opacity:0;
				transition: opacity;
				transition-duration:0.2s;
			}
			.average {
				display: none;
			}
		}
		
	}

	.similarTypes {
		margin-bottom: 0;
	}

	.similar{
		margin: 0;
		text-align: center;
		li {
			list-style: none;
			display: inline-block;
		}
		a {
			margin: 0 10px;
			display: inline-block;
			font-size: 0.9em;
		}
	}
}

