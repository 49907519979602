/* @group APP */
#root {
	height: 100%;
}
.App {
	height: 100%;
	overflow-x: hidden;
}

.App .hideOnUserSignedOut,
.App .hideOnUserSignedIn {
	display: none;
}

.App.userSignedIn div.hideOnUserSignedOut,
.App.userSignedOut div.hideOnUserSignedIn,
.App.userSignedIn p.hideOnUserSignedOut,
.App.userSignedOut p.hideOnUserSignedIn,
.App.userSignedIn li.hideOnUserSignedOut,
.App.userSignedOut li.hideOnUserSignedIn {
	display: block;
}

/* @group MESSAGE */
.message {
	position: relative;
	opacity: 0.5;
	padding: 0 $global-margin;
	background-repeat: no-repeat;
	background-position: 2.3rem 1.2rem;
	background-size: 15px auto;
	&.success,
	&.pending,
	&.error {
		opacity: 1;
	}
	&.success {
		background-image: url("../skeleton/images/done.png");
	}
	&.pending {
	}
	&.error {
		background-image: url("../skeleton/images/error.png");
	}
}

// .ContentNode {
	// border: 1px solid red;
	// border-radius: 1em;
	// padding: 0.5em;
	// h2 {
	// 	font-size: 1em;
	// }
	// &.c3m_poi:before {
	// 	content: "+";
	// 	font-size: 2em;
	// 	color: red;
	// }
	// &.c3m_puzzle:before {
	// 	content: "X";
	// 	font-size: 2em;
	// 	color: pink;
	// }
	// &.c3m_city_story:before {
	// 	content: "C";
	// 	font-size: 2em;
	// 	color: yellow;
	// }
// }

// .Contribution {
	// border: 1px solid red;
	// border-radius: 1em;
	// padding: 0.5em;
	// h2 {
	// 	font-size: 1.2em;
	// 	margin: 0;
	// }
	// h3 {
	// 	font-size: 1em;
	// 	margin: 0;
	// }
	// .points {
	// 	float: right;
	// }

	/* just a list of all possible contribution types in case we want different styling */
	// &.SIGNUP {                 background: #eee; }
	// &.DAILY_LOGIN {            background: #eee; }
	// &.COMPLETE_PROFILE {       background: #eee; }
	// &.VISIT_ANOTHER_CITY {     background: #eee; }
	// &.RATE_CONTENT {           background: #eee; }
	// &.SOLVE_PUZZLE {           background: #eee; }
	// &.COMPLETE_CITY_STORY {    background: #eee; }
	// &.SUBMIT_CITY_SURVEY {     background: #eee; }
	// &.SHARE_SOCIAL {           background: #eee; }
	// &.POST_DISCUSSION {        background: #eee; }
	// &.CREATE_POI {             background: #eee; }
	// &.CREATE_PUZZLE {          background: #eee; }
	// &.CREATE_CITY_STORY {      background: #eee; }
	// &.INVITE_FB_FRIEND {       background: #eee; }
	// &.EDIT_POI {               background: #eee; }
	// &.SOLVE_CITY_STORY_PART {  background: #eee; }
// }