/* @group MAP */
.MapNavigator {
	height: 100%;
}





/* @group LEAFLET */
.MapNavigator .leaflet-container {
	height: 100%;
	font-size: 1rem !important;

	// city centers should be small and solid when looking at countries/europe
	&.z4 path.city-center { stroke-width: 5; }
	&.z5 path.city-center { stroke-width: 7; }

	// city centers should be large and solid when looking at a country
	&.z6,
	&.z7,
	&.z8,
	&.z9,
	&.z10 {
		path.city-center {
			stroke-width: 10;
		}
	}
	&.z11 {
		path.city-center {
			stroke-width: 9;
		}	
	}
	& .leaflet-map-pane {
		transition: opacity 80ms;
	}
	&.background .leaflet-map-pane {
		opacity: 0.6;
		transition: opacity 300ms;
	}
}

.leaflet-control-container {
	.MapRecenterControl {
		order: 2;
		button {
			background: #fff url(../pages/map/icons/recenter.png) no-repeat 50% 50%;
			width: $app-map-controls-size;
			height: $app-map-controls-size;
			&:disabled {
				opacity: 0.4;
				cursor: default;
			}
		}
		span {
			display: none;
		}
	}
	.ViewCityInfoControl {
		order: 4;
		button,
		.button {
			padding-left: 0;
			padding-right: 0;
			background-color: $app-color-duk;
			width: $app-map-controls-size;
			height: $app-map-controls-size;
			@extend [class^="icon-"];
			@extend .icon-duk;
			@extend .solo;
			color: #fff;
			&:before {
				font-size: 1.2em;
				line-height: 1.5em;
			}
			&:hover {
				background-color: darken($app-color-duk, 10%);
			}
		}
		span {
			display: none;
		}
	}

	button,
	.button {
		border: 0;
		cursor: pointer;
		border-radius: 1.8rem;
		transition: background-color 0.2s;
		&:hover {
			background-color: lighten($primary-color, 60%);
		}
	}


	/* @group BOTTOM RIGHT */
	.leaflet-bottom.leaflet-right {
		display: flex;
		bottom: $global-margin !important;
		// align-items: center;
		.leaflet-control {
			filter: drop-shadow($app-shadow);
			margin: 0 $global-margin 0 0 !important;
			float: none !important;
		}
		.leaflet-control-zoom {
			order: 1;
		}
		.leaflet-control-attribution.leaflet-control {
			order: 0;
			filter: none;
			position: fixed;
			left: 0.5rem;
			bottom: 2rem;
			* {
				color: $black
			}
			/* our buttons are on the default 800 but this comes after them and we want it behind */
			z-index: 750;
		}
	}

	.leaflet-control-zoom a {
		font-weight: normal !important;
		border-bottom: 1px solid #ccc !important;
		display: inline-block !important;
		vertical-align: bottom !important;
		width: $app-map-controls-size !important;
		height: $app-map-controls-size !important;
		line-height: $app-map-controls-size !important;
		color: $primary-color !important;
		transition: background-color 0.2s;
		&:hover {
			background-color: lighten($primary-color, 60%);
		}
		&:first-child {
			border-radius: 0 100% 100% 0 !important;
			float: right;
		}
		&:last-child {
			border-radius: 100% 0 0 100% !important;
			float: left;
		}
		&.leaflet-disabled {
			background: $light-gray !important;
			color: $dark-gray !important;
		}
	}
	@include breakpoint(small only) {
		.ViewCityInfoControl {
			display: none;
		}
	}
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: 0 !important;
}

.leaflet-marker-pane {
	.YouAreHereIcon {
		display: inline-block;
		border-radius: 50%;
		z-index: 0 !important;
	}
	.PoiIcon {
		filter: brightness(100%);
		transition: filter 0.3s;
		&:before {
			display: inline-block;
			position: absolute;
			z-index: +1;
			bottom: 0;
			right: 0;
			width: 17px;
			height: 17px;
			font-size: 10px;
			line-height: 16px;
			text-align: center;
			vertical-align: middle;
			background: $app-color-puzzle;
			border: 1px solid rgba(#fff, 0.7);
			color: #fff;
			border-radius: 50%;
		}
		&:hover {
			filter: brightness(110%);
		}
		span {
			display: block;
			text-align: center;
			height: 100%;
			position: relative;
			&:before {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				display: block;
				height: 100%;
				width: 100%;
				line-height: rem-calc(46);
				vertical-align: middle;
				border-radius: 50%;
				background-color: $app-color-poi;
				// background: $app-color-duk;
				color: #fff;
				box-shadow: $app-shadow-large;
			}
		}
		&.active {
			/* pop it above all markers but below SlidingPanel */
			z-index: 10000 !important;
			/* make it pop a bit */
			filter: hue-rotate(5deg) drop-shadow(0 0 8px #222);
		}
		&.small {
			span {
				font-size: 0.85rem;
				&:before {
					content: "";
					line-height: 2em;
				}
			}
		}
		&.large {
			span {
				font-size: 1.4rem;
				&:before {
					// background-color: $app-color-poi;
					line-height: rem-calc(43);
				}
			}
		}
		/* C3M-147 */
		&.large.active {
			span {
				font-size: 1.8rem;
				&:before {
					line-height: rem-calc(54);
				}
			}
		}
		&.extra-1:before {
			content: "1";
		}
		&.extra-2:before {
			content: "2";
		}
		&.extra-3:before {
			content: "3";
		}
		&.extra-4:before {
			content: "4";
		}
		&.extra-5:before {
			content: "5";
		}
		&.extra-5-plus:before {
			content: "5+";
		}
		// &.museums-galleries span:before       { background: $app-color-museums-galleries;    }
		// &.cultural-heritage-sites span:before { background: $app-cultural-heritage-sites;  }
		// &.tourist-attractions span:before     { background: $app-tourist-attractions;   }
		// &.city-amenities span:before          { background: $app-city-amenities;   }
		// &.cinemas-theatres span:before        { background: $app-cinemas-theatres; }
		// &.city-stories span:before            { background: $app-city-stories; }
		&.museums-galleries span:before {
			border-color: $app-color-museums-galleries;
			color: $app-color-museums-galleries;
		}
		&.cultural-heritage-sites span:before {
			border-color: $app-cultural-heritage-sites;
			color: $app-cultural-heritage-sites;
		}
		&.tourist-attractions span:before {
			border-color: $app-tourist-attractions;
			color: $app-tourist-attractions;
		}
		&.city-amenities span:before {
			border-color: $app-city-amenities;
			color: $app-city-amenities;
		}
		&.cinemas-theatres span:before {
			border-color: $app-cinemas-theatres;
			color: $app-cinemas-theatres;
		}
		&.city-stories span:before {
			border-color: $app-city-stories;
			color: $app-city-stories;
		}
	}
}

/* @group LEAFLET TOP-LEFT */
.leaflet-top.leaflet-left {
	// padding: $global-padding $global-padding*2;
	padding: 2vh 2vh;
	max-width: 300px;
	.leaflet-control {
		margin: 0;
	}
	input {
		margin: 0;
		height: auto;
		border-radius: 1.8rem;
		padding: 0.815rem 1.3em;
	}
	@include breakpoint(medium) {
		max-width: none;
		// padding: $global-padding;
		display: flex;
		// padding: $global-padding * 2;
	}
}

/* @group LEAFLET in preview */

.MapLocationPreview {
	position: relative;
	width: 100%;
	min-width: rem-calc(300);
	height: rem-calc(220);
	overflow: hidden;
	.leaflet-container {
		height: 100%;
	}
	.leaflet-control-container {
		height: 100%;
	}
	.leaflet-right {
		right: auto;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.leaflet-marker-draggable {
		cursor: all-scroll;
	}
	.leaflet-control-container .leaflet-bottom.leaflet-right .leaflet-control-attribution.leaflet-control {
		/* we agreed with NV to only show the attribution on the main map, and not on the small preview maps */
		display: none;
	}
}

.CityStoryMapPreview {
	position: relative;
	z-index: 0;
	width: 100%;
	min-width: rem-calc(300);
	height: rem-calc(220);
	overflow: hidden;
	margin-bottom: $global-margin*2;
	border-radius: $global-radius;
	.leaflet-container {
		height: 100%;
	}
	.leaflet-control-container {
		height: 100%;
	}
	.leaflet-right {
		right: auto;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.leaflet-control-container .leaflet-bottom.leaflet-right .leaflet-control-attribution.leaflet-control {
		/* we agreed with NV to only show the attribution on the main map, and not on the small preview maps */
		display: none;
	}
	svg path {
		stroke-dasharray: 10;
		stroke-width: 2px;
		stroke: black;
		stroke-opacity: 0.6;
		stroke-linecap: butt;
		animation: dash 1s linear infinite;
	}
	@keyframes dash {
	  to {
	    stroke-dashoffset: -20;
	  }
	}


	.PoiIcon.solved,
	.PoiIcon.visited {
		span {
			&::before {
				line-height: 2.7;
				font-size: 0.7em;
				content: $icon-check;
				background: $success-color;
			}
		}
	}
	.PoiIcon.current {
		span {
			&:before {
				line-height: 1.5;
				font-size: 1.2em;
				content: $icon-here;
				box-sizing: content-box;
				// border: 2px solid white;
				background: $app-color-puzzle;
			}
		}
	}
	.PoiIcon.current.solved,
	.PoiIcon.current.visited {
		span:before {
			content: $icon-check $icon-here ;
			white-space: pre;
			font-size: 0.8em;
			line-height: 2.2;
		}
	}
}

/* @group SEARCH */

.leaflet-top.leaflet-left .leaflet-control.SearchTermControl {
	float: none;
	clear: none;
	margin-bottom: $global-margin*0.5;
	@include breakpoint(medium) {
		margin: 0 $global-margin 0 0;
	}
	input {
		box-shadow: $app-shadow;
		padding-left: 3rem;
		background: #fff;
	}
	&:before {
		position: absolute;
		top: 0.9rem;
		left: 1rem;
		font-size: 1.5rem;
		width: 1.5rem;
		height: 1.5rem;
		color: $anchor-color;
	}
}

/* @group CATEGORY FILTERS */
.leaflet-top.leaflet-left .leaflet-control.SearchCategoryControl {
	float: none;
	clear: none;
	flex-shrink:0;
	position: relative;
	@include breakpoint(medium) {
		margin: 0 $global-margin 0 0;
	}
	.icon-filters {
		width: calc(100% + #{$global-padding} + #{$global-padding});
		margin: -1.125rem -#{$global-padding} 0 -#{$global-padding};
		padding: 1.125rem 1.3rem;
		font-size: 0.9rem;
		font-family: "square-medium";
		text-align: left;
		color: $anchor-color;
		&:before {
			line-height: 0;
		}
	}
	> div {
		background: #fff;
		border: $input-border;
		border-radius: 1.8rem;
		box-shadow: $app-shadow;
		padding: #{$global-padding} #{$global-padding} 0 #{$global-padding};
		line-height: 1;
		&.expanded {
			box-shadow: $app-shadow-large;
		}
	}
	div.expanded ul {
		display: block;
		margin-bottom: $global-margin;
		max-height: 60vh;
		overflow: -moz-scrollbars-vertical;
		overflow-y: scroll;
		-webkit-overflow-scrolling: auto;
		@include breakpoint(large) {
			max-height: none;
			overflow-y: visible;
		}
		@media only screen 
			 and (min-device-height: 568px) 
			  and (max-device-height: 667px) 
			  and (-webkit-min-device-pixel-ratio: 2)
			  and (orientation: portrait) { 
				max-height: 53vh;
		}
		@extend %scrollbar;
	}
	div.collapsed ul {
		display: none;
	}
	ul {
		list-style: none;
		margin: 0 0 0rem 0;
		// padding: 0 1rem 1rem 1rem;
	}

	li {
		label {
			input[checked] {
				background-color: lighten($primary-color, 60%);
			}
			&:before {
				color: $app-color-duk;
				color: $dark-gray;
			}
		}
		&.all {

			label {
				// font-size: 0.7em;
				// padding: 0.5em 1em 0.5em 3.4em;
				// text-align: right;
				// border-bottom: 1px solid $light-gray;
				// margin-bottom: 1rem;
				&:before {
					// color: $app-color-poi;
					// display: none;
				}
			}
		}
		// &.museums-galleries label {
		// 	&:before,
		// 	[checked] + span {
		// 		color: $app-color-museums-galleries;
		// 	}
		// 	input[checked] {
		// 		background-color: lighten($app-color-museums-galleries, 47%);
		// 	}
		// }
		// &.cultural-heritage-sites label {
		// 	&:before,
		// 	[checked] + span {
		// 		color: $app-cultural-heritage-sites;
		// 	}
		// 	input[checked] {
		// 		background-color: lighten($app-cultural-heritage-sites, 45%);
		// 	}
		// }
		// &.tourist-attractions label {
		// 	&:before,
		// 	[checked] + span {
		// 		color: $app-tourist-attractions;
		// 	}
		// 	input[checked] {
		// 		background-color: lighten($app-tourist-attractions, 51%);
		// 	}
		// }
		// &.city-amenities label {
		// 	&:before,
		// 	[checked] + span {
		// 		color: $app-city-amenities;
		// 	}
		// 	input[checked] {
		// 		background-color: lighten($app-city-amenities, 44%);
		// 	}
		// }
		// &.cinemas-theatres label {
		// 	&:before,
		// 	[checked] + span {
		// 		color: $app-cinemas-theatres;
		// 	}
		// 	input[checked] {
		// 		background-color: lighten($app-cinemas-theatres, 53%);
		// 	}
		// }
		&.category-key-city-stories {
			&:after, &:before {
				content: "";
				display: block;
				margin: 0.5em 0;
				border-bottom: 1px solid $light-gray;
			}		
			label {
				&:before,
				[checked] + span {
					color: $app-city-stories;
				}
				input[checked] {
					background-color: lighten($app-city-stories, 45%);
				}
			}
		}
	}
	label {
		cursor: pointer;
		padding: 0.5em 1.3rem 0.5em 2.4rem;
		display: block;
		position: relative;
		color: $anchor-color;
		&:before {
			position: absolute;
			z-index: 2;
			left: 0.8rem;
			top: 0;
			bottom: 0;
			margin: auto;
			display: block;
			height: 0.9em;
			// color: $black;
			font-size: 1.15em;
		}
		input {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			z-index: 0;
			width: 100%;
			padding: 0;
			appearance: none;
			pointer-events: none;
			border:0;
		}
		span {
			position: relative;
			z-index: 1;
		}
	}
}

.leaflet-top {
	z-index: 1100 !important; /* override library's 1000 */
}

.leaflet-tile-pane {
	// 01
  	// filter: contrast(86%) brightness(91%) saturate(229%) sepia(49%) hue-rotate(140deg) grayscale(90%);
	// 02
  	filter: contrast(86%) brightness(91%) saturate(229%) sepia(49%) hue-rotate(278deg) grayscale(90%);
	// 03
  	// filter: contrast(100%) brightness(91%) saturate(229%) sepia(49%) hue-rotate(278deg) grayscale(90%);
  	// 04
  	// filter: contrast(70%) brightness(102%) saturate(229%) sepia(0%) hue-rotate(-122deg) grayscale(90%);
  	// 05
  	// filter: contrast(70%) brightness(100%) saturate(0%) sepia(15%) hue-rotate(-136deg) grayscale(0%);
  	// 06
  	// filter: contrast(80%) brightness(104%) saturate(0%) sepia(15%) hue-rotate(354deg) grayscale(0%);
}