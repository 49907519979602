/* @group MESSAGES */
	p.empty,
	p.serverError,
	p.solved,
	p.wrong,
	p.success,
	p.rateSuccess,
	p.pending,
	p.correct,
	p.closed {
		text-align: center;
		margin-bottom: $global-margin*2;
		// padding-left: 3rem;
		// position: relative;
		&:before{
			// position: absolute;
			// left: 0;
			width: 3rem;
			height: 3rem;
			display: block;
			margin: 0 auto 1rem;
			line-height: 2.1rem;
			font-size: 1.4rem;
			background: $app-color-red;
			color: #fff;
			border-radius: 50%;
			padding: 0.5rem;
			vertical-align: text-top;
		}
	}
	p.correct:before,
	p.success:before,
	p.rateSuccess:before,
	p.solved:before{
		background: $app-color-green;
	}
	p.pending:before {
		background: $warning-color;
	}

	/* @group POINTS */

	.points {
		font-family: 'square-regular';
		&:before {
			color: $app-color-gold;
		}
	}