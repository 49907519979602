/* @group HEADER */
.main > .row > header {
	height: $header-height-mobile;
	@include breakpoint(medium) {
		height: $header-height;
	}
	.row {
		height: 100%;
	}
	.columns {
		border-left: 1px solid $light-gray;
		display: flex;
		height: 100%;
		align-items: center;
		@include breakpoint(small only) {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
		&:first-child {
			background: url(../skeleton/images/european-comission-logo.png);
			background-repeat: no-repeat;
			background-position: rem-calc(52) rem-calc(10);
			border-left: 0;
			padding-left: 0;
			background-size: auto 50%;
			@include breakpoint(medium) {
				background-position: rem-calc(90) rem-calc(10);
				padding-left: $global-padding * 7;
				background-size: auto 70%;
				max-width: none;
			}
		}
	}
	padding-left: 0;
}

/* @group TITLE */
.AppTitle {
	display: flex;
	align-items: middle;
	h1 {
		font-family: "square-medium";
		text-transform: uppercase;
		color: $black;
		font-size: rem-calc(12);
		line-height: 1;
		margin: 0;
		padding-left: rem-calc(112);
		// padding-right: rem-calc(0);
		@include breakpoint(medium) {
			font-size: rem-calc(17);
			padding-left: rem-calc(110);
			// padding-right: 0;
		}
	}
}

/* @group USER */
.UserAvatar img {
	width: rem-calc(42);
	height: rem-calc(42);
	border-radius: 100%;
	object-fit: cover;
	box-shadow: $app-shadow;
	@include breakpoint(medium) {
		width: auto;
	}
}

.UserArea {
	font-size: 0.8em;
	> button {
		cursor: pointer;
		@include breakpoint(large) {
			margin-left: $global-margin;
		}
	}
	.sign-in {
		@include breakpoint(medium down) {
			text-indent: -9999px;
			position: relative;
			width: 100%;
			padding: 0.5rem 0;
			&:before {
				position: absolute;
				left: 0;
				display: block;
				text-indent: 0;
				margin: auto;
				width: 100%;
			}
		}
	}
	@include breakpoint(medium) {
		padding: 0 $global-padding;
	}
	> * {
		display: inline-block;
		vertical-align: middle;
	}
	.score {
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		&:before {
			display: inline-block;
			border-radius: 100%;
			background-color: $app-color-gold;
			color: #fff;
			padding: rem-calc(6)
		}
	}
	.notifications {
		display: inline-block;
		vertical-align: middle;
		border-radius: $global-radius;
		text-align: center;
		color: #fff;
		background: $app-color-red;
		padding: rem-calc(4) rem-calc(9);
		min-width: 1.7rem;
		margin-left: $global-margin;
	}
	.button {
		margin-bottom: 0;
		&.hollow {
			color: $anchor-color !important
		}
	}
	.UserAvatar {
		display: inline-block;
		@include breakpoint(small only) {
			img {
				width: rem-calc(32);
				height: rem-calc(32);
			}
		}
		@include breakpoint(medium) {
			margin-left: $global-margin;
		}
	}
	ul {
		margin-bottom: 0;
		.button, button {
			text-align: left;
		}
	}
	li {
		margin-bottom: 0.5rem;
		&:first-child {
			padding: $button-padding;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.popup {
		width: 100vw;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 10000;
		background: #fff;
		padding: 2em;
		box-shadow: $app-shadow;
		opacity: 0.3;
		transform: translate3d(100%, 0, 0);
		transition: transform 50ms, opacity 50ms; /* fast close */
		&.visible {
			opacity: 1;
			transform: translate3d(0%, 0, 0);
			transition: transform 200ms, opacity 200ms; /* slow open */
		}
		@include breakpoint(medium) {
			width: 50vw;
			max-width: rem-calc(330);
		}
	}
}
