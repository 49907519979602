// @font-face {
//   font-family: "square-light";
//   src: url("../skeleton/fonts/ecsquaresanspro-light-web.eot");
//   src: url("../skeleton/fonts/ecsquaresanspro-light-web.eot?#iefix") format("embedded-opentype"),
//     url("../skeleton/fonts/ecsquaresanspro-light-web.woff") format("woff"),
//     url("../skeleton/fonts/ecsquaresanspro-light-web.ttf") format("truetype"),
//     url("../skeleton/fonts/ecsquaresanspro-light-web.svg#ECSquareSansProLight") format("svg");
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: "square-regular";
  src: url("../skeleton/fonts/ecsquaresanspro-regular-web.eot");
  src: url("../skeleton/fonts/ecsquaresanspro-regular-web.eot?#iefix") format("embedded-opentype"),
    url("../skeleton/fonts/ecsquaresanspro-regular-web.woff") format("woff"),
    url("../skeleton/fonts/ecsquaresanspro-regular-web.ttf") format("truetype"),
    url("../skeleton/fonts/ecsquaresanspro-regular-web.svg#ECSquareSansProRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "square-medium";
  src: url("../skeleton/fonts/ecsquaresanspro-medium-web.eot");
  src: url("../skeleton/fonts/ecsquaresanspro-medium-web.eot?#iefix") format("embedded-opentype"),
    url("../skeleton/fonts/ecsquaresanspro-medium-web.woff") format("woff"),
    url("../skeleton/fonts/ecsquaresanspro-medium-web.ttf") format("truetype"),
    url("../skeleton/fonts/ecsquaresanspro-medium-web.svg#ECSquareSansProMedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "square-bold";
  src: url("../skeleton/fonts/ecsquaresanspro-bold-web.eot");
  src: url("../skeleton/fonts/ecsquaresanspro-bold-web.eot?#iefix") format("embedded-opentype"),
    url("../skeleton/fonts/ecsquaresanspro-bold-web.woff") format("woff"),
    url("../skeleton/fonts/ecsquaresanspro-bold-web.ttf") format("truetype"),
    url("../skeleton/fonts/ecsquaresanspro-bold-web.svg#ECSquareSansProBold") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* @group ICONS */

$icomoon-font-family: "culturalGems" !default;
$icomoon-font-path: "../skeleton/fonts" !default;

$icon-c3m_puzzle: "\e90a";
$icon-check: "\e909";
$icon-distance: "\e908";
$icon-duk: "\e900";
$icon-fame: "\e901";
$icon-home: "\e902";
$icon-info: "\e903";
$icon-places: "\e904";
$icon-pois: "\e905";
$icon-sign-in: "\e906";
$icon-data: "\e907";
$icon-facebook: "\e90b";
$icon-twitter: "\e90c";
$icon-youtube: "\e90d";
$icon-filters: "\e90e";
$icon-gem: "\e90f";
$icon-favourite: "\e910";
$icon-external: "\e911";
$icon-arrow-down: "\e912";
$icon-star-half: "\e914";
$icon-star: "\e913";
$icon-sign-out: "\e915";
$icon-user: "\e916";
$icon-edit: "\e917";
$icon-remove: "\e918";
$icon-search: "\e919";
$icon-create-poi: "\e91a";
$icon-wait: "\e91b";
$icon-arrow-left: "\e91c";
$icon-here: "\e91d";
$icon-story-add: "\e91f";
$icon-arrow-up: "\e920";
$icon-contribute: "\e921";
$icon-share: "\e922";
$icon-hide: "\e923";
$icon-show: "\e924";
$icon-add: "\e925";
$icon-talk: "\e926";
$icon-lock: "\e927";
$icon-city-data-economy: "\e928";
$icon-city-data-environment: "\e929";
$icon-city-data-vibrancy: "\e92a";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff2") format("woff2"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf") format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff") format("woff"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  text-decoration: none !important;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-right: 0.5rem;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"],
[class*=" icon-"] {
  &.solo:before {
    margin-right: 0;
  }
  &.button:before {
    vertical-align: top;
  }
}

.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-duk,
.icon-POST_DISCUSSION {
  &:before {
    content: $icon-duk;
  }
}
.icon-fame {
  &:before {
    content: $icon-fame;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-places,
.icon-VISIT_ANOTHER_CITY {
  &:before {
    content: $icon-places;
  }
}
.icon-c3m_poi,
.icon-pois,
.icon-CREATE_POI,
.icon-EDIT_POI {
  &:before {
    content: $icon-pois;
  }
}
.icon-sign-in,
.icon-SIGNUP,
.icon-DAILY_LOGIN {
  &:before {
    content: $icon-sign-in;
  }
}
.icon-SUBMIT_CITY_SURVEY,
.icon-SUBMIT_CITY_SURVEY,
.icon-data {
  &:before {
    content: $icon-data;
  }
}
.icon-distance {
  &:before {
    content: $icon-distance;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-c3m_puzzle,
.icon-SOLVE_PUZZLE,
.icon-CREATE_PUZZLE {
  &:before {
    content: $icon-c3m_puzzle;
  }
}
.icon-filters {
  &:before {
    content: $icon-filters;
  }
}
.icon-c3m_city_story,
.icon-gem,
.icon-SOLVE_CITY_STORY_PART,
.icon-CREATE_CITY_STORY,
.icon-COMPLETE_CITY_STORY {
  &:before {
    content: $icon-gem;
  }
}
.icon-favourite {
  &:before {
    content: $icon-favourite;
  }
}
.icon-external {
  &:before {
    content: $icon-external;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}
.icon-star,
.icon-RATE_CONTENT {
  &:before {
    content: $icon-star;
  }
}
.icon-user,
.icon-COMPLETE_PROFILE {
  &:before {
    content: $icon-user;
  }
}
.icon-sign-out {
  &:before {
    content: $icon-sign-out;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-remove {
  &:before {
    content: $icon-remove;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-create-poi {
  &:before {
    content: $icon-create-poi;
  }
}
.icon-wait {
  &:before {
    content: $icon-wait;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-here {
  &:before {
    content: $icon-here;
  }
}
.icon-story-add {
  &:before {
    content: $icon-story-add;
  }
}

.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}

.icon-contribute {
  &:before {
    content: $icon-contribute;
  }
}

.icon-INVITE_FB_FRIEND,
.icon-SHARE_SOCIAL,
.icon-share {
  &:before {
    content: $icon-share;
  }
}

.icon-hide {
  &:before {
    content: $icon-hide;
  }
}

.icon-show {
  &:before {
    content: $icon-show;
  }
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}

.icon-c3m_online_discussion,
.icon-talk {
  &:before {
    content: $icon-talk;
  }
}

.icon-lock {
  &:before {
    content: $icon-lock;
  }
}

.icon-city-data-economy {
  &:before {
    content: $icon-city-data-economy;
  }
}

.icon-city-data-environment {
  &:before {
    content: $icon-city-data-environment;
  }
}

.icon-city-data-vibrancy {
  &:before {
    content: $icon-city-data-vibrancy;
  }
}