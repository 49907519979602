.PoiItemPanel {

	/* @group ANSWERS */

	ul.answers {
		list-style: none;
		margin: 0 (-$global-padding);
		background: #fff;
		padding: $global-padding * 2;
		border-radius: $global-padding $global-padding 0 0;
		@include breakpoint(medium) {
			padding: $global-padding * 3;
			margin: 0 (-$global-padding * 3);
		}
		li {
			button {
				display: block;
				width: 100%;
				font-family: "square-bold";
				font-size: 1rem;
				border-radius: $global-margin * 5;
				position: relative;
				outline: none;
				&:not(.success):not(.alert) {
					background: rgba($app-color-puzzle, 0.2);
					color: $app-color-puzzle;
					&:before {
						content: "";
					}
				}
				&:focus {
					color: #fff;
				}
				&:before {
					position: absolute;
					top: -0.85rem;
					right: -0.85rem;
					color: #fff;
					border-radius: 100%;
					transform: scale(0, 0);
					line-height: 1.8rem;
					text-align: center;
					border: 2px solid #fff;
					width: 2rem;
					height: 2rem;
					transition: transform 0.1s;
				}
				&.success:before,
				&.alert:before {
					transform: scale(1, 1);
				}
				&.success:before {
					background: $success-color;
				}
				&:disabled {
					opacity: 0.9;
					cursor: default;
				}
			}
			&:last-child {
				button {
					margin-bottom: 0;
				}
			}
		}
	}
}

/* @group DIFFICULTY */
.Difficulty {
	display: inline-block;
	background-image: url("../skeleton/images/difficulty.png");
	background-repeat: no-repeat;
	min-height: 1.3rem;
	min-width: 1.1rem;
	span {
		margin: 0 0 0 $global-padding * 2;
	}
	&.easy {
		background-position: 0px 0px;
	}
	&.moderate {
		background-position: 0px -43px;
	}
	&.difficult {
		background-position: 0px -86px;
	}
}

/* @group SOURCE */
.Source {
	font-style: italic;
	font-size: 0.9em;
	span {
		opacity: 0.7;
	}
}
