html {
	height: 100%;
}
body {
	height: 100%;
}

/* @group SELECT */
select {
	background-image: url(../skeleton/images/select-arrow.svg);
}

@-moz-document url-prefix() {
	select {
		text-indent: -2px;
	}
}

/* @group LINKS */

a[rel*="external"],
a[target="_blank"] {
	&:after {
		font-family: 'culturalGems';
		content: $icon-external;
		margin-left: rem-calc(4);
		opacity: 0.6;
	}
}

/* @group HEADERS */

h2 {
	font-family: "square-medium";
}
h3 {
	font-family: "square-bold";
	margin-bottom: $paragraph-margin-bottom;
}
h4 {
	font-family: "square-bold";
	margin-bottom: $h4-margin-bottom;
}

/* @group BUTTONS */

	button.large,
	.button.large {
		font-family: "square-medium";
	}
	button.invert,
	.button.invert {
		color: $anchor-color;
	}
	button.secondary,
	.button.secondary {
		color: $anchor-color;
	}

	.button.promo {
		border-radius: 1.6rem;
	}

	.button-group .button {
		font-size: inherit;
	}

/* @group CALLOUT */
.callout {
	// font-family: "square-medium", arial, sans-serif;
	font-size: 1.2em;
	color: darken($dark-gray, 10%);
	> *:last-child {
		margin-bottom: 0;
	}
}


/* @group CSS clickables */
	
	.ex-col-trigger {
		&:before {
			display: inline-block;
			font-size: 0.6em;
			margin-right: 2rem;
			color: $app-color-duk;
			transform: rotate(0deg);
			transition: transform 0.3s
		}
	}

	.ex-col-content{
		p, ul {
			font-size: 0;
			line-height: 0;
			transition: line-height 0.5s, opacity 0.3s 0.2s, margin-top 0.5s;
			margin-bottom: 0;
			opacity: 0;
		}
		h3 {
			margin-bottom: 0;
		}
		p {
			margin-top: 0;
		}
		a {
			display: block;
			padding-left: 3rem;
			position: relative;
			&:before {
				position: absolute;
				left: 0;
				top: 0.5rem;
			}
		}
	}

	.ex-col-content:target{
		.ex-col-trigger {
			color: $app-color-puzzle;
			&:before {
				color: $black;
				transform: rotate(270deg);
			}
		}
		p {
			margin-top: $global-margin;
		}
		p, ul {
			font-size: 1rem;
			line-height: $paragraph-lineheight;
			margin-bottom: $paragraph-margin-bottom;
			opacity: 1;
		}
	}

/* @group FORM */
	
	select, [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea, [type='file'] {
		transition: background-color 0.3s;
	}
	[type='file'] {
		border-radius: $select-radius;
	}
	select {
		-webkit-appearance: none;
	}
	textarea::placeholder,
	input::placeholder {
		color: darken($input-placeholder-color,30%);
		font-style: italic;
		// color: red;
	}

	// Checkboxes

		input[type='checkbox'] {
			opacity: 0;
			margin-right: -24px;
			& + label {
				position: relative;
				padding-left: $global-padding*3;
				line-height: 1.2;
				opacity: 0.7;
				transition: opacity 0.3s;
				&:after {
					content: "";
					display: inline-block;
					width: 20px;
					height: 20px;
					border: 1px solid $anchor-color; 
					border-radius: $global-radius;
					position: absolute;
					left: 0;
					top: 0px;
				}
				&:before {
					font-family: "#{$icomoon-font-family}";
					opacity: 0;
					content: "";
					position: absolute;
					left: 3px;
					top: 2px;
					font-size: 0.85rem;
					transition: opacity 0.3s;
				}
			}
			&:checked + label {
				// font-family: "square-medium";
				@extend .icon-check;
				opacity: 1;
				&:before {
					opacity: 1;
				}
			}

			// Negative version
			// used in ContentOwnershipConfirmationField
			&.negative + label:after {
				border-color: white;
			}
		}

