.Favourite {
	margin:0;
	background: transparent;
	font-size: 0;
	position: relative;
	&:before {
		color: $app-color-green;
		font-size: 1.9rem;
	}
	&:after {
		text-align: center;
		font-size: 0.8rem;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		height: 0.9rem;
		font-family: 'culturalGems';
		content: $icon-check;
		color: $black;
		color: #fff;
	}
	&.isNotFavourite {
		&:before {
			color: #fff;
		}
		&:after {
			font-family: $body-font-family;
			content: "+";
			height: 1.4rem;
			font-size: 1.2rem;
			color: $black;
		}
	}
	&:hover {
		background: transparent;
		&:before {
			color: #fff;
		}
		&:after {
			color: $black
		}
	}
}